import styled from 'styled-components';
import Grid from "Components/Atoms/Grid";
import StepTitle from "Components/Atoms/StepTitle";
import Input from "Components/Atoms/Input";
// import InputMask from 'react-input-mask';
import dynamic from 'next/dynamic';

const DynamicMask = dynamic(() => import('react-input-mask'));

export const Col = styled(Grid)``;
export const Row = styled(Grid)``;

export const Wrapper = styled.div`
    padding: 40px 25px 15px;

    @media ${(props) => props.theme.device.tablet} {
        padding: 25px 30px 10px;
    }
`

export const CustomStepTitle = styled(StepTitle)``;

export const CustomInput = styled(Input)``;

export const CustomMask = styled(DynamicMask)``;
