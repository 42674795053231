import React from "react";
import * as S from "./styles";

const CustomerDataFields = ({
    fields,
    onEventChange,
    onEventBlur,
    ...props
}) => {
    return (
        <S.Wrapper data-testid="CustomerDataFields">
            <S.Row container justifyContent={"center"} alignItems={"center"}>
                <S.Col item xs={12}>
                    <S.CustomStepTitle
                        number={2}
                        title={"Informe seus dados"}
                    />
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={"center"} alignItems={"center"}>
                <S.Col item xs={12}>
                    <S.CustomInput
                        type="text"
                        id="input-name"
                        label="Nome"
                        name="name"
                        defaultValue={fields.name}
                        onChange={onEventChange}
                        onBlur={onEventBlur}
                        autoComplete={"off"}
                    />
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={"center"} alignItems={"center"}>
                <S.Col item xs={12}>
                    <S.CustomMask
                        mask={"99 99999-9999"}
                        maskChar={null}
                        onChange={onEventChange}
                        onBlur={onEventBlur}
                        value={fields.phone}
                    >
                        {(inputProps) => (
                            <S.CustomInput
                                {...inputProps}
                                type="text"
                                id="input-phone"
                                label="Celular"
                                name="phone"
                                placeholder={"99 99999-9999"}
                                autoComplete={"off"}
                            />
                        )}
                    </S.CustomMask>
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={"center"} alignItems={"center"}>
                <S.Col item xs={12}>
                    <S.CustomInput
                        type="email"
                        id="input-email"
                        label="E-mail"
                        name="email"
                        defaultValue={fields.email}
                        onChange={onEventChange}
                        onBlur={onEventBlur}
                        autoComplete={"off"}
                    />
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={"center"} alignItems={"center"}>
                <S.Col item xs={12}>
                    <S.CustomMask
                        mask={"999.999.999-99"}
                        maskChar={null}
                        onChange={onEventChange}
                        onBlur={onEventBlur}
                        defaultValue={fields.cpf}
                    >
                        {(inputProps) => (
                            <S.CustomInput
                                {...inputProps}
                                type="text"
                                id="input-cpf"
                                label="CPF"
                                name="cpf"
                                onChange={onEventChange}
                                autoComplete={"off"}
                            />
                        )}
                    </S.CustomMask>
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={"center"} alignItems={"center"}>
                <S.Col item xs={12}>
                    <S.CustomInput
                        type="password"
                        id="input-password"
                        label="Crie ou insira sua senha"
                        name="password"
                        onChange={onEventChange}
                        onBlur={onEventBlur}
                    />
                </S.Col>
            </S.Row>
        </S.Wrapper>
    );
};

export default CustomerDataFields;
